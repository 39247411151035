const apiBaseUrl = 'cart_domain_replaced_during_build';
const regex = /.00/g;
const qtyInput = document.querySelector('.qty-input');
const totalPriceContainer = document.querySelector('.total-price');

// EX: FH: Jefferson AL, Kansas City MO, San Bernardino County CA, Mobile County AL, Lexington-Fayette KY, Chesapeake VA, Houston TX, Oregon, Hampton & Peninsula VA, Bullit KY
const excludedFromBulkPricingPIDList = [
  4135, 3730, 3628, 3953, 4078, 3922, 3806, 3753, 3538, 4109, 3749, 4008, 4140, 15, 39, 275, 3483, 3618, 3633, 3796, 3148,
  4153, 4161, 4167, 4169, 4168, 4192, 4194, 4198, 4205, 4209, 4228, 4232, 4233, 4251, 4250, 4254, 4266, 4276, 4278, 4277,
  4283, 3642, 4347, 4348, 4350, 1047, 3764,
];
const displayBulkMessagePIDList = [4346, 4136];

const getFoodManagerPricingData = (categoryId) => {
  const url = `${apiBaseUrl}/CpsApi/getProductCards?categoryId=${categoryId}`;

  fetch(url, { credentials: 'include', mode: 'cors' })
    .then(checkFetchStatus)
    .then((data) => {
      if (data) {
        data.data.forEach((item) => {
          const productTypeContainer = document.querySelector(`.${item.cfpmType.toLowerCase()}-${item.productId}`);
          const coursePriceContainer = productTypeContainer.querySelector('.course-price');
          coursePriceContainer.classList.add('shown');
          if (item.cfpmType === 'Bundle') {
            const discountedPriceContainer = productTypeContainer.querySelector('.crossed-out-price');
            if (item.defaultDiscountedPrice) {
              discountedPriceContainer.innerHTML = `$${Math.round(Number(item.totalPrice))}`;
              coursePriceContainer.innerHTML = `$${Math.round(Number(item.defaultDiscountedPrice))}`;
            } else {
              coursePriceContainer.innerHTML = `$${Math.round(Number(item.totalPrice))}`;
            }
          } else {
            coursePriceContainer.innerHTML = `$${Math.round(Number(item.totalPrice))}`;
          }
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const getPricingData = (categoryId) => {
  const url = `${apiBaseUrl}/CategoryApi/getCategoryProduct?categoryItemId=${categoryId}`;

  fetch(url, { credentials: 'include', mode: 'cors' })
    .then(checkFetchStatus)
    .then((data) => {
      if (data) {
        const priceContainer = document.querySelector('.course-price');
        priceContainer.classList.add('shown');
        const coursePrice = data.data[0].courseFee.replace(regex, '');
        priceContainer.innerHTML = `${coursePrice}`;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const getHACCPPricingData = (categoryIds) => {
  categoryIds.forEach((id) => {
    const url = `${apiBaseUrl}/CategoryApi/getCategoryProduct?categoryItemId=${id}`;
    fetch(url, { credentials: 'include', mode: 'cors' })
      .then(checkFetchStatus)
      .then((data) => {
        if (data) {
          const productColumn = document.querySelector(`.category-${id}`);
          const priceContainer = productColumn.querySelector('.course-price');
          priceContainer.classList.add('shown');
          priceContainer.innerHTML = `${data.data[0].totalPrice.replace(regex, '')}`;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

const getProductPagePricingData = (categoryId) => {
  const url = `${apiBaseUrl}/CategoryApi/getCategoryProduct?categoryItemId=${categoryId}`;

  fetch(url, { credentials: 'include', mode: 'cors' })
    .then(checkFetchStatus)
    .then((data) => {
      if (data) {
        const coursePriceContainer = document.querySelector('.course-price');
        coursePriceContainer.classList.add('shown');
        coursePriceContainer.innerHTML = `${data.data[0].courseFee}`;
        let thirdPartyFee = 0;
        let thirdPartyFeeBoolean = false;
        // Check if the course has third party fees
        if (
          data.data[0].showThirdPartyFees === true &&
          data.data[0].thirdPartyFee &&
          data.data[0].thirdPartyFee !== '$0.00'
        ) {
          // If the course has third party fees, display the fee
          const hdFeeContainer = document.querySelector('.fee-price');
          hdFeeContainer.classList.add('shown');
          hdFeeContainer.innerHTML = `${data.data[0].thirdPartyFee}`;
          thirdPartyFee = Number(data.data[0].thirdPartyFee.replace('$', ''));
          thirdPartyFeeBoolean = true;
        }
        updateTotal(data, qtyInput.value, thirdPartyFee, thirdPartyFeeBoolean);

        // Add an event listener to the quantity input to update the total price when the quantity is changed
        document.addEventListener('change', (e) => {
          // Get the updated quantity value
          let updatedQty = qtyInput.value;
          // Check if the event target is the quantity input
          if (e.target === qtyInput) {
            if (updatedQty < 1) {
              updatedQty = 1;
              qtyInput.value = 1;
            } else if (updatedQty > 999) {
              updatedQty = 999;
              qtyInput.value = 999;
            }
            updateTotal(data, updatedQty, thirdPartyFee, thirdPartyFeeBoolean);
          }
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const getProductPageUpsellPricingData = (productId, categoryId) => {
  const url = `${apiBaseUrl}/upsellApi/loadUpsellProducts?productId=${productId}&categoryItemId=${categoryId}`;

  fetch(url, { credentials: 'include', mode: 'cors' })
    .then(checkFetchStatus)
    .then((data) => {
      if (data) {
        const upsells = data.data.nonPrintCartDisplayValues.lineItems;
        if (upsells) {
          let upsellTotal = 0;
          upsells.forEach((upsell) => {
            const upsellContainer = document.querySelector(`.upsell-${upsell.productId}`);
            upsellTotal += Number(Number(upsell.subtotal.replace('$', '')).toFixed(2));
            if (upsell.discount) {
              const upsellDiscountedPriceContainer = upsellContainer.querySelector('.discounted-price');
              upsellDiscountedPriceContainer.classList.add('shown');
              upsellDiscountedPriceContainer.innerHTML = `${upsell.unitPriceBeforeDiscount}`;
            }
            const upsellTotalContainer = document.querySelector('.upsell-total');
            const priceContainer = upsellContainer.querySelector('.course-price');
            upsellTotalContainer.classList.add('shown');
            upsellTotalContainer.innerHTML = `Total Price: $${upsellTotal.toFixed(2)}`;
            priceContainer.classList.add('shown');
            priceContainer.innerHTML = `${upsell.subtotal}`;
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

function updateTotal(data, qty, thirdPartyFee, thirdPartyFeeBoolean) {
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const coursePrice = data.data[0].courseFee.replace('$', ''); // coursePrice is the cost of the course alone without any fees
  const totalPrice = data.data[0].totalPrice.replace('$', ''); // totalPrice is the cost of the course with any fees if they exist
  totalPriceContainer.classList.add('shown');
  // Initialize displayedPrice and crossed out price
  let displayedPrice = 0;
  let crossedOutPrice = '';
  // Check to make sure the course is not in the excludedFromBulkPricingPIDList array
  if (excludedFromBulkPricingPIDList.includes(data.data[0].productId) == false) {
    // Check if the course is in the displayBulkMessagePIDList array
    if (displayBulkMessagePIDList.includes(data.data[0].productId)) {
      // if the quantity is greater than 5, display the bulk pricing message
      if (qty > 5) {
        const bulkMessageEl = document.querySelector('.bulk-pricing-message');
        // if the bulk pricing message already exists, update the displayedPrice
        if (bulkMessageEl) {
          displayedPrice = (totalPrice * qty).toFixed(2);
        } else {
          // if the bulk pricing message does not exist, display the bulk pricing message and update the displayedPrice
          displayedPrice = (totalPrice * qty).toFixed(2);
          const bulkPricingMessage = document.createElement('p');
          bulkPricingMessage.classList.add('text-center', 'bulk-pricing-message', 'mb-0');
          bulkPricingMessage.innerHTML = 'Bulk discount may apply. See discount in cart.';
          const totalRowContainer = document.querySelector('.total-row');
          totalRowContainer.insertAdjacentElement('afterend', bulkPricingMessage);
        }
      } else {
        // if the quantity is less than 5, remove the bulk pricing message and update the displayedPrice
        const bulkPricingMessage = document.querySelector('.bulk-pricing-message');
        if (bulkPricingMessage) {
          bulkPricingMessage.remove();
        }
        displayedPrice = (totalPrice * qty).toFixed(2);
        crossedOutPrice = '';
      }
    } else {
      let discountedPrice = 0;
      let discountAmount = 0;
      // if the course is not in the displayBulkMessagePIDList array or in the excludedFromBulkPricingPIDList array
      if (qty > 29) {
        // if the quantity is greater than 29, apply a 10% discount
        // this rounds the discount amount to the nearest hundredth and rounds 5 and below down and 6 and above up
        discountAmount = Math.floor(coursePrice * 0.1 * 100) / 100;
        // if there is a third party fee, add the fee to the discounted price
        if (thirdPartyFeeBoolean) {
          discountedPrice = Number(USDollar.format(coursePrice - discountAmount + thirdPartyFee).replace('$', ''));
        } else {
          discountedPrice = Number(USDollar.format(coursePrice - discountAmount).replace('$', ''));
        }
        crossedOutPrice = (totalPrice * qty).toFixed(2);
        displayedPrice = (discountedPrice * qty).toFixed(2);
      } else if (qty > 5) {
        // if the quantity is greater than 5, apply a 5% discount
        // this rounds the discount amount to the nearest hundredth and rounds 5 and below down and 6 and above up
        discountAmount = Math.floor(coursePrice * 0.05 * 100) / 100;
        // if there is a third party fee, add the fee to the discounted price
        if (thirdPartyFeeBoolean) {
          discountedPrice = Number(USDollar.format(coursePrice - discountAmount + thirdPartyFee).replace('$', ''));
        } else {
          discountedPrice = Number(USDollar.format(coursePrice - discountAmount).replace('$', ''));
        }
        crossedOutPrice = (totalPrice * qty).toFixed(2);
        displayedPrice = (discountedPrice * qty).toFixed(2);
      } else {
        // if the quantity is less than 5, display the displayedPrice and no crossed out price
        displayedPrice = (totalPrice * qty).toFixed(2);
        crossedOutPrice = '';
      }
    }
    // Create the total price wrapper
    const totalPriceWrapper = `
      <div>
        ${crossedOutPrice ? `<span class="crossed-out-price fw-light">$${crossedOutPrice}</span>` : ''}
        $${displayedPrice}
      </div>
    `;
    // Insert the total price wrapper into the total price container
    totalPriceContainer.innerHTML = totalPriceWrapper;
  } else {
    // if the course is in the excludedFromBulkPricingPIDList array
    // display the displayedPrice as is returned from the API
    displayedPrice = (totalPrice * qty).toFixed(2);
    crossedOutPrice = '';
    const totalPriceWrapper = `
      <div>
        ${crossedOutPrice ? `<span class="crossed-out-price fw-light">$${crossedOutPrice}</span>` : ''}
        $${displayedPrice}
      </div>
    `;
    totalPriceContainer.innerHTML = totalPriceWrapper;
  }
}
